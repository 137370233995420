import React, { useState, useEffect } from 'react';
import './App.css'; 
import SuccessMessage from './components/SuccessMessage/SuccessMessage';
import LogInGoogle from './components/LogInGoogle/LogInGoogle';
import Header from './components/Header/Header';  
import PendingOrders from './components/PendingOrders/PendingOrders'; 
import UserForm from './components/UserForm/UserForm'; 
import ErrorMessage from './components/ErrorMessage/ErrorMessage';
import Card from './components/InfoCard/InfoCard';
import Cuestionario from './components/cuestionario/cuestionario';
import SelectCenter from './components/SelectCenter/SelectCenter'; // Importa el nuevo componente



function App() {
  const [token, setToken] = useState(null);
  const [message, setMessage] = useState(''); // Para mensajes de éxito o informativos
  const [error, setError] = useState(''); // Para mensajes de error
  const [subject, setSubject] = useState('');
  const [coverLetter, setCoverLetter] = useState('');
  const [file, setFile] = useState(null);
  const [tokenRemain, setTokenRemain] = useState(null);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [centersList, setCentersList] = useState([]);
  const [selectedCenters, setSelectedCenters] = useState([]); // Nuevo estado para los centros seleccionados
  
  const SCOPES = 'https://www.googleapis.com/auth/gmail.modify'; //https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly
  
  // Define la URL de redirección dependiendo del entorno
  const CLIENT_ID = '611025911367-dlctm9qg7f37qdhhm5febk1s6df36qcc.apps.googleusercontent.com';
  const REDIRECT_URI = 'https://backend.bteacher.es/oauth2callback'
  const API_URL = 'https://backend.bteacher.es' 

  //const CLIENT_ID = '611025911367-bujdqr6t42l1pn89dukap83d84rrbasn.apps.googleusercontent.com';
  //const REDIRECT_URI = 'http://localhost:3001/oauth2callback'
  //const API_URL = 'http://localhost:3001'


  const handleSelectedCentersChange = (selectedCenters) => {
    setSelectedCenters(selectedCenters);
  };
  

  const fetchCenters = async (productId) => {
    try {
      const response = await fetch(`${API_URL}/api/centros-educativos?product_id=${productId}`);
      const data = await response.json();
  
      if (response.ok) {
        setCentersList(data.data);
      } else {
        setError(data.error || 'Error al obtener los centros');
      }
    } catch (error) {
      setError('Error al obtener los centros educativos');
      console.error(error);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = urlParams.get('token');
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
      getTokenRemain(tokenFromUrl);
      getPendingOrders(tokenFromUrl);
    }
  }, []);


  const handleOrderSelection = (orderId) => {
    setSelectedOrder(orderId);
    const selectedProduct = pendingOrders.find(order => order.order_id === orderId);
    
    if (selectedProduct) {
      fetchCenters(selectedProduct.product_id);
    }
  };


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = urlParams.get('token');
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
      getTokenRemain(tokenFromUrl);
      getPendingOrders(tokenFromUrl);
    }
  }, []);

  const getTokenRemain = async (token) => {
    try {
      const response = await fetch(`${API_URL}/api/user-data`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setTokenRemain(data.token_remain);
      } else {
        setError(data.error); // Usar setError para manejar errores
      }
    } catch (error) {
      setError('Error al obtener el número de ejecuciones restantes.');
    }
  };

  const getPendingOrders = async (token) => {
    try {
      const response = await fetch(`${API_URL}/api/orders/pending`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setPendingOrders(data.data);
      } else {
        setError(data.error); // Usar setError para manejar errores
      }
    } catch (error) {
      setError('Error al obtener los pedidos pendientes.');
    }
  };

  const handleAuthClick = () => {
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=${SCOPES}&access_type=offline`;
    window.location.href = authUrl;
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };



  const sendEmail = async () => {
    if (!token) {
      setError('No estás autenticado.');
      return;
    }
  
    if (!selectedOrder) {
      setError('Por favor, selecciona un pedido antes de enviar el correo.');
      return;
    }
  
    if (!subject.trim()) {
      setError('Por favor, ingresa un asunto para el correo.');
      return;
    }
  
    if (!coverLetter.trim()) {
      setError('Por favor, escribe una carta de presentación.');
      return;
    }
  
    if (!file) {
      setError('Por favor, adjunta tu CV antes de enviar el correo.');
      return;
    }
  
    if (tokenRemain < 1) {
      setError('No tienes suficientes créditos para enviar el CV.');
      return;
    }
  
    setLoading(true);
  
    const selectedProduct = pendingOrders.find(order => order.order_id === selectedOrder);
  
    if (!selectedProduct) {
      setError('El pedido seleccionado no es válido.');
      setLoading(false);
      return;
    }
  
    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('message', coverLetter);
    formData.append('product_id', selectedProduct.product_id);
    formData.append('order_id', selectedProduct.order_id);
    formData.append('centers', JSON.stringify(selectedCenters)); // Envía la lista de centros seleccionados
  
    if (file) {
      formData.append('file', file);
    }
  
    try {
      const response = await fetch(`${API_URL}/api/send-email`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
  
      const data = await response.json();
      if (response.ok) {
        setSuccess(true);
        setMessage('Correo enviado exitosamente.');
        getTokenRemain(token);
        getPendingOrders(token);
  
        // Aquí restablecemos los estados para ocultar el listado de centros y limpiar la selección de pedido
        setSelectedOrder(null);
        setCentersList([]);  // Vaciamos la lista de centros para ocultarla
        setSelectedCenters([]); // Limpia la selección de centros
        
      } else {
        setError(data.error || 'Error al enviar el correo.');
      }
    } catch (error) {
      setError('Error al enviar el correo. Intenta de nuevo más tarde.');
      console.error('Error al enviar el correo:', error);
    } finally {
      setLoading(false);
    }
  };
  
  

  const handleLogout = async () => {
    if (!token) {
      setError('No estás autenticado.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/api/logout`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setMessage(data.message); // Usar setMessage para mensajes de éxito
      setToken(null);
      setTokenRemain(0);
      setPendingOrders([]);
      setMessage('Sesión cerrada correctamente.'); // Usar setMessage para mensajes de éxito
      window.history.replaceState(null, '', window.location.pathname);

    } catch (error) {
      setError('Error al cerrar sesión.'); // Usar setError para manejar errores
    }
  };



  const handleCloseError = () => {
    setError(''); // Función para cerrar el mensaje de error
  };

  return (
    <div className="App">
      <Header />
      {error && <ErrorMessage message={error} onClose={handleCloseError} />}

      {!token && (
      <>
        <p className="welcome-message">
          <strong>¡Bienvenido a la plataforma de envío de CV!</strong>
        </p>

        <div className="card-container">
          <Card
            heading="1. Inicia Sesión con Google"
            description="Concédenos permisos de acceso tu cuenta de Gmail para que podamos enviar tu CV en tu nombre de manera segura"
            image="https://cdn-icons-png.flaticon.com/512/272/272456.png"  
          />
          <Card
            heading="2. Rellena tu CV"
            description="Rellena la información de tu CV para que se la enviemos a los centros."
            image="https://cdn-icons-png.flaticon.com/512/4898/4898440.png"  
          />
          <Card
            heading="3. ¡Envialo a los centros!"
            description="¡Listo! Tu CV ha sido enviado a los colegios. Solo espera su respuesta."
            image="https://cdn-icons-png.flaticon.com/512/1034/1034313.png" 
          />
        </div>

        <div className="google-login-btn-container">
          <div className="button-container">
            <LogInGoogle onClick={handleAuthClick} />
          </div>
        </div>  

        <div className="footer-container">
          <div className="privacy-links">
            <a href="https://bteacher.es/pages/politica-de-privacidad-de-la-app-de-envios" target="_blank" rel="noopener noreferrer">Política de Privacidad</a>
            <span> | </span>
            <a href="https://bteacher.es/pages/terminos-del-servicio-de-app-envios-bteacher" target="_blank" rel="noopener noreferrer">Términos y Condiciones</a>
            <span> | </span>
            <a href="mailto:info@bteacher.es">Contacto</a>
          </div>
        </div>
      </>
    )}


      {token && !success && (
        <>
          <div className="title-section">
            <h2>📩 Selecciona el envío que quieres realizar:</h2>
            <PendingOrders pendingOrders={pendingOrders} selectedOrder={selectedOrder} handleOrderSelection={handleOrderSelection} />
          </div>
          <div className="title-section">
            <SelectCenter centersList={centersList} onSelectedCentersChange={handleSelectedCentersChange} />
          </div>
          <Cuestionario subject={subject} coverLetter={coverLetter} file={file} setSubject={setSubject} setCoverLetter={setCoverLetter} handleFileChange={handleFileChange} sendEmail={sendEmail} />
          <button className="logout-btn" onClick={handleLogout}>Eliminar Accesos</button>
        </>
      )}

      {success && <SuccessMessage onDismiss={() => setSuccess(false)} />}
    </div>
  );
}

export default App;
