// src/components/cuestionario/cuestionario.js

import React, { useState, useEffect } from 'react';
import './cuestionario.css';
import SendButton from '../SendButton/SendButton'; // Importa el componente SendButton

function Cuestionario({ subject, coverLetter, file, setSubject, setCoverLetter, handleFileChange, sendEmail }) {
  // Estados para los campos del formulario
  const [nombre, setNombre] = useState('');
  const [titulacion, setTitulacion] = useState('');
  const [especialidad, setEspecialidad] = useState('');
  const [nivelIngles, setNivelIngles] = useState('N/D');
  const [deca, setDeca] = useState('NO');
  const [otros, setOtros] = useState('');
  const [loading, setLoading] = useState(false); // Estado de carga

  // Estado inicial para la carta de presentación con un ejemplo por defecto
  useEffect(() => {
    if (!coverLetter) {
      setCoverLetter(
        'Estimado/a director/a,\n\n' +
        'Me dirijo a usted para expresar mi interés en formar parte de su equipo docente. Soy una persona comprometida y con experiencia en el ámbito educativo, y creo que podría contribuir positivamente al desarrollo de su centro.\n\n' +
        'Un saludo,'
      );
    }
  }, [coverLetter, setCoverLetter]);

  // Actualiza el campo de "Asunto" automáticamente
  useEffect(() => {
    const updateAsunto = () => {
      const partes = [`CV ${nombre}`, titulacion, especialidad];

      if (['B2', 'C1', 'C2'].includes(nivelIngles)) {
        partes.push(`Inglés ${nivelIngles}`);
      }

      if (deca === 'SI') {
        partes.push('DECA');
      }

      if (otros) {
        partes.push(otros);
      }

      setSubject(partes.filter(Boolean).join(' - '));
    };

    updateAsunto();
  }, [nombre, titulacion, especialidad, nivelIngles, deca, otros, setSubject]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Activa el loader
    await sendEmail();
    setLoading(false); // Desactiva el loader después de enviar el correo
  };

  return (
    <div className="questionnaire-container">
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      <form className="questionnaire-form" onSubmit={handleSubmit}>
        <div className="data-box">
          <h3 className="data-box-title">Datos personales y académicos</h3>
          <div className="line-item-property__field">
            <label htmlFor="nombre" className="label">Nombre Completo:</label>
            <input id="nombre" type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} className="full-width-input" required />
          </div>
          <div className="line-item-property__field">
            <label htmlFor="titulacion" className="label">Titulación:</label>
            <input id="titulacion" type="text" value={titulacion} onChange={(e) => setTitulacion(e.target.value)} className="full-width-input" required />
          </div>
          <div className="line-item-property__field">
            <label htmlFor="especialidad" className="label">Especialidad:</label>
            <input id="especialidad" type="text" value={especialidad} onChange={(e) => setEspecialidad(e.target.value)} className="full-width-input" />
          </div>
          <div className="line-item-property__field">
            <label htmlFor="nivelIngles" className="label">Nivel Certificado de Inglés:</label>
            <select id="nivelIngles" value={nivelIngles} onChange={(e) => setNivelIngles(e.target.value)} className="select-field" required>
              <option value="N/D">N/D</option>
              <option value="A1">A1</option>
              <option value="A2">A2</option>
              <option value="B1">B1</option>
              <option value="B2">B2</option>
              <option value="C1">C1</option>
              <option value="C2">C2</option>
            </select>
          </div>
          <div className="line-item-property__field">
            <label htmlFor="deca" className="label">DECA:</label>
            <select id="deca" value={deca} onChange={(e) => setDeca(e.target.value)} className="select-field" required>
              <option value="NO">NO</option>
              <option value="SI">SI</option>
            </select>
          </div>
          <div className="line-item-property__field">
            <label htmlFor="otros" className="label">Otros:</label>
            <input id="otros" type="text" value={otros} onChange={(e) => setOtros(e.target.value)} className="full-width-input" />
          </div>
        </div>

        <div className="data-box">
          <h3 className="data-box-title">Información del envío por correo</h3>
          <div className="line-item-property__field">
            <label htmlFor="asunto" className="label">Asunto de correo:</label>
            <textarea id="asunto" value={subject} onChange={(e) => setSubject(e.target.value)} className="scrollable-input" rows="3" />
          </div>
          <div className="line-item-property__field">
            <label htmlFor="cartaPresentacion" className="label">Carta Presentación:</label>
            <textarea id="cartaPresentacion" value={coverLetter} onChange={(e) => setCoverLetter(e.target.value)} className="full-width-textarea" rows="10" />
          </div>
          <div className="line-item-property__field">
            <label htmlFor="cv" className="label">CV:</label>
            <input id="cv" type="file" onChange={handleFileChange} className="full-width-input" required />
          </div>
        </div>

        <SendButton onClick={handleSubmit} disabled={loading}>
          ENVIAR CV A CENTROS
        </SendButton>
      </form>
    </div>
  );
}

export default Cuestionario;
