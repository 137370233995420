// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 12px;
    gap: 16px;
  }
  
  .pagination-arrow {
    background: none;
    border: none;
    color: #333;
    font-size: 18px;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
  }
  
  .pagination-arrow:disabled {
    color: #e0e0e0;
    cursor: not-allowed;
  }
  
  .pagination-arrow:hover:not(:disabled) {
    color: #28a745;
  }
  
  .pagination-dots {
    display: flex;
    gap: 6px;
  }
  
  .pagination-dot {
    width: 8px;
    height: 8px;
    background-color: #ccc;
    border-radius: 50%;
    transition: background-color 0.3s;
  }
  
  .pagination-dot.active {
    background-color: #28a745;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Pagination/Pagination.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,SAAS;EACX;;EAEA;IACE,gBAAgB;IAChB,YAAY;IACZ,WAAW;IACX,eAAe;IACf,eAAe;IACf,kCAAkC;EACpC;;EAEA;IACE,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,QAAQ;EACV;;EAEA;IACE,UAAU;IACV,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,iCAAiC;EACnC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".pagination-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding-top: 12px;\n    gap: 16px;\n  }\n  \n  .pagination-arrow {\n    background: none;\n    border: none;\n    color: #333;\n    font-size: 18px;\n    cursor: pointer;\n    transition: color 0.2s ease-in-out;\n  }\n  \n  .pagination-arrow:disabled {\n    color: #e0e0e0;\n    cursor: not-allowed;\n  }\n  \n  .pagination-arrow:hover:not(:disabled) {\n    color: #28a745;\n  }\n  \n  .pagination-dots {\n    display: flex;\n    gap: 6px;\n  }\n  \n  .pagination-dot {\n    width: 8px;\n    height: 8px;\n    background-color: #ccc;\n    border-radius: 50%;\n    transition: background-color 0.3s;\n  }\n  \n  .pagination-dot.active {\n    background-color: #28a745;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
