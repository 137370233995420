// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* From Uiverse.io by Yaya12085 */ 
button {
  max-width: 320px;
  display: flex;
  padding: 0.5rem 1.4rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
  gap: 0.75rem;
  color: rgb(65, 63, 63);
  background-color: #fff;
  cursor: pointer;
  transition: all .6s ease;
}

.button svg {
  height: 24px;
}

button:hover {
  transform: scale(1.02);
}

.google-login-btn-container {
  height: 10; /* Esto asegura que el contenedor ocupe toda la altura de la ventana */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/LogInGoogle/LogInGoogle.css"],"names":[],"mappings":"AAAA,iCAAiC;AACjC;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,oBAAoB;EACpB,gBAAgB;EAChB,kBAAkB;EAClB,yBAAyB;EACzB,sBAAsB;EACtB,mBAAmB;EACnB,qBAAqB;EACrB,qCAAqC;EACrC,YAAY;EACZ,sBAAsB;EACtB,sBAAsB;EACtB,eAAe;EACf,wBAAwB;AAC1B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,UAAU,EAAE,sEAAsE;EAClF,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":["/* From Uiverse.io by Yaya12085 */ \nbutton {\n  max-width: 320px;\n  display: flex;\n  padding: 0.5rem 1.4rem;\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  font-weight: 700;\n  text-align: center;\n  text-transform: uppercase;\n  vertical-align: middle;\n  align-items: center;\n  border-radius: 0.5rem;\n  border: 1px solid rgba(0, 0, 0, 0.25);\n  gap: 0.75rem;\n  color: rgb(65, 63, 63);\n  background-color: #fff;\n  cursor: pointer;\n  transition: all .6s ease;\n}\n\n.button svg {\n  height: 24px;\n}\n\nbutton:hover {\n  transform: scale(1.02);\n}\n\n.google-login-btn-container {\n  height: 10; /* Esto asegura que el contenedor ocupe toda la altura de la ventana */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 20px;\n  margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
