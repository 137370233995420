// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-section {
    margin-top: 20px;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .full-width-textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 10px 0;
  }
  
  .file-input {
    margin: 10px 0;
  }
  
  .action-btn {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .action-btn:hover {
    background-color: #45a049;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/UserForm/UserForm.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,cAAc;IACd,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".form-section {\n    margin-top: 20px;\n  }\n  \n  .input-field {\n    width: 100%;\n    padding: 10px;\n    margin: 10px 0;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n  }\n  \n  .full-width-textarea {\n    width: 100%;\n    height: 100px;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    margin: 10px 0;\n  }\n  \n  .file-input {\n    margin: 10px 0;\n  }\n  \n  .action-btn {\n    background-color: #4caf50;\n    color: white;\n    padding: 10px 20px;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .action-btn:hover {\n    background-color: #45a049;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
