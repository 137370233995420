import React, { useState, useEffect } from 'react';
import './App.css'; 
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom'; // Importar Router y sus helpers

import SuccessMessage from './components/SuccessMessage/SuccessMessage';
import LogInGoogle from './components/LogInGoogle/LogInGoogle';
import Header from './components/Header/Header';  
import PendingOrders from './components/PendingOrders/PendingOrders'; 
import ErrorMessage from './components/ErrorMessage/ErrorMessage';
import Card from './components/InfoCard/InfoCard';
import Cuestionario from './components/cuestionario/cuestionario';
import SelectCenter from './components/SelectCenter/SelectCenter'; // Importa el nuevo componente
import AdminDashboard from './pages/AdminDashboard/AdminDashboard';


function App() {
  const [token, setToken] = useState(null);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [subject, setSubject] = useState('');
  const [coverLetter, setCoverLetter] = useState('');
  const [file, setFile] = useState(null);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [authSuccess, setAuthSuccess] = useState(false); // Asegúrate de inicializar aquí
  const [Success, setSuccess] = useState(false); // Asegúrate de inicializar aquí
  const [centersList, setCentersList] = useState([]);
  const [selectedCenters, setSelectedCenters] = useState([]);
  const navigate = useNavigate(); // Declara navigate aquí
  const [isAdmin, setIsAdmin] = useState(false); // Estado para determinar si es admin


  
  const SCOPES = 'https://www.googleapis.com/auth/gmail.modify'; //https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly

  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
  const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;
  const API_URL = process.env.REACT_APP_API_URL;


 
// Funciones para manejar localStorage
  const AUTH_SUCCESS_KEY = 'authSuccess';
  const SESSION_ID_KEY = 'sessionId';
  const SESSION_STATE_KEY = 'sessionState';
  const setLocalStorage = (key, value) => {localStorage.setItem(key, value);};
  const getLocalStorage = (key) => {return localStorage.getItem(key);};
  const removeLocalStorage = (key) => {localStorage.removeItem(key);};




  const fetchCenters = async (productId) => {
    try {
      const response = await fetch(`${API_URL}/api/centros-educativos?product_id=${productId}`);
      const data = await response.json();

      

      if (response.ok) {
        setCentersList(data.data);
      } else {
        console.error('Error desde el backend:', data.error);
        setError(data.error || 'Error al obtener los centros.');
      }
    } catch (error) {
      console.error('Error al obtener los centros educativos:', error);
      setError('Error al obtener los centros educativos.');
    }
  };
  

  
  useEffect(() => {
    const checkAdmin = async () => {
      try {
        const response = await fetch(`${API_URL}/api/check-admin`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });


        if (response.ok) {
          const data = await response.json();
          if (data.isAdmin) {
            setIsAdmin(true); // Usuario es administrador
          } else {
            setIsAdmin(false); // Usuario no tiene permisos
          }
        } else {
          console.error('Error verificando permisos de administrador.');
          setIsAdmin(false); // Por defecto, no mostrar el botón
        }
      } catch (error) {
        console.error('Error al verificar permisos:', error);
        setIsAdmin(false);
      }
    };

    checkAdmin();
  }, [token]);





    useEffect(() => {
    // Obtener parámetros de la URL
    const urlParams = new URLSearchParams(window.location.search);
    const authSuccessParam = urlParams.get('authSuccess'); // Bandera de autenticación exitosa
    const storedState = getLocalStorage(SESSION_STATE_KEY); // Obtener el estado almacenado
    const storedAuth = getLocalStorage(AUTH_SUCCESS_KEY); // Obtener flag de éxito de autenticación

    if (authSuccessParam === 'true' && storedState) {
        console.log('Autenticación exitosa y el estado coincide. Guardando datos...');
        setAuthSuccess(true); // Marcar como autenticado
        setLocalStorage(AUTH_SUCCESS_KEY, 'true'); // Guardar éxito de autenticación
        
        const sessionId = localStorage.getItem(SESSION_ID_KEY);
        const combinedToken = `${storedState}bteacher${sessionId}`;
        setToken(combinedToken); // Guarda el token combinado en el estado
        localStorage.setItem('sessionToken', combinedToken); // Opcional: guardarlo en localStorage
        
        //window.history.replaceState(null, '', window.location.pathname); // Limpiar la URL
    }
    else {
        console.error('El estado recibido no coincide con el almacenado. Autenticación fallida.');
        setAuthSuccess(false);
      }
    }
    , []);


    const handleOrderSelection = (order) => {
      console.log('Pedido seleccionado en App.js:', order); // Muestra el objeto completo del pedido seleccionado
      setSelectedOrder(order); // Guarda el pedido completo en el estado
      fetchCenters(order.product_id); // Usa el product_id del pedido seleccionado para buscar centros
    };



  const handleAuthClick = async () => {
    try {
      let sessionId = getLocalStorage(SESSION_ID_KEY);
      if (!sessionId) {
        sessionId = crypto.randomUUID(); // Genera un nuevo sessionId
        setLocalStorage(SESSION_ID_KEY, sessionId); // Guarda en localStorage
      }
  
      const sessionInfo = {
        sessionId,
        userAgent: navigator.userAgent,
        language: navigator.language,
      };
  
  
      // Llamada al backend para generar el estado
      const response = await fetch(`${API_URL}/api/generate-state`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(sessionInfo),
      });
  
      if (!response.ok) {
        throw new Error('Error al generar el estado para la autenticación.');
      }
  
      const { state } = await response.json();
      if (!state) {
        throw new Error('No se recibió un estado válido del backend.');
      }
  
      setLocalStorage(SESSION_STATE_KEY, state); // Guarda el estado en localStorage
  
      // Construir la URL de autenticación
      const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=${SCOPES}&access_type=offline&state=${state}`;
  
      // Redirigir al usuario a Google para autenticación
      window.location.href = authUrl;
    } catch (error) {
      console.error('Error durante el proceso de autenticación:', error.message);
      alert('Hubo un problema al iniciar el proceso de autenticación. Por favor, intenta de nuevo.');
  
      // Limpieza en caso de error
      removeLocalStorage(SESSION_ID_KEY);
      removeLocalStorage(SESSION_STATE_KEY);
    }
  };
  
  



  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };



  const sendEmail = async () => {
    if (!token) {
      setError('No estás autenticado.');
      return;
    }
  
    if (!selectedOrder) {
      setError('Por favor, selecciona un pedido antes de enviar el correo.');
      return;
    }
  
    if (selectedCenters.length === 0) {
      setError('Por favor, selecciona al menos un centro.');
      return;
    }
  
  
    setLoading(true);
  
  
    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('message', coverLetter);
    formData.append('product_id', selectedOrder.product_id);
    formData.append('order_id', selectedOrder.order_id);
    formData.append('centers', JSON.stringify(selectedCenters)); // Solo los seleccionados
  
  
    if (file) {
      formData.append('file', file);
    }
  
    try {
      const response = await fetch(`${API_URL}/api/send-email`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
  
      const data = await response.json();
      if (response.ok) {
        setSuccess(true);
        setMessage('Correo enviado exitosamente.');
        setSelectedOrder(null);
        setCentersList([]); // Limpia la lista de centros
        setSelectedCenters([]); // Limpia los seleccionados
      } else {
        setError(data.error || 'Error al enviar el correo.');
      }
    } catch (error) {
      setError('Error al enviar el correo. Intenta de nuevo más tarde.');
      console.error('Error al enviar el correo:', error);
    } finally {
      setLoading(false);
    }
  };
  
  
  const handleLogout = async () => {
    try {
      // Verifica si el token está disponible
      if (!token) {
        throw new Error('No hay un token disponible para cerrar sesión.');
      }
  
      // Llamada al backend para cerrar la sesión
      const response = await fetch(`${API_URL}/api/logout`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`, // Usa el token combinado
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error al cerrar la sesión en el backend.');
      }
  
      const data = await response.json();
      console.log(data.message); // Muestra el mensaje del backend
  
      // Eliminar todos los parámetros almacenados en localStorage
      localStorage.clear(); // Limpia completamente el almacenamiento local
      console.log('Todos los datos locales eliminados.');
  
      // Actualizar el estado local
      setMessage('Sesión cerrada correctamente.');
      setAuthSuccess(false); // Restablecer autenticación
      setToken(null); // Limpiar el token
  
      // Limpiar la URL sin recargar la página
      window.history.replaceState(null, '', window.location.pathname);
    } catch (error) {
      console.error('Error durante el cierre de sesión:', error.message);
      alert('Error al cerrar sesión. Por favor, intenta nuevamente.');
    }
  };



  return (
    <Routes>
      <Route
        path="/"
        element={
          <div className="App">
            {Success ? (
              <SuccessMessage onDismiss={() => setSuccess(false)} />
            ) : (
              <>
                <Header />
                {error && <ErrorMessage message={error} onClose={() => setError('')} />}
                {!authSuccess ? (
                  <>
                    <p className="welcome-message">
                      <strong>¡Bienvenido a la plataforma de envío de CV!</strong>
                    </p>
                    <div style={{ height: '20px' }}></div>
                    <div className="google-login-btn-container">
                      <LogInGoogle onClick={handleAuthClick} />
                    </div>
                    <div style={{ height: '20px' }}></div> 

                    <div className="card-container">
                      <Card
                        heading="1. Inicia Sesión con Google"
                        description="Concédenos permisos de acceso a tu cuenta de Gmail para que podamos enviar tu CV de manera segura."
                        image="https://cdn-icons-png.flaticon.com/512/272/272456.png"
                      />
                      <Card
                        heading="2. Rellena tu CV"
                        description="Rellena la información de tu CV para que se la enviemos a los centros."
                        image="https://cdn-icons-png.flaticon.com/512/4898/4898440.png"
                      />
                      <Card
                        heading="3. ¡Envialo a los centros!"
                        description="¡Listo! Tu CV ha sido enviado a los colegios. Solo espera su respuesta."
                        image="https://cdn-icons-png.flaticon.com/512/1034/1034313.png"
                      />
                    </div>
                    
                    <div className="footer-container">
                      <div className="privacy-links">
                        <a
                          href="https://bteacher.es/pages/politica-de-privacidad-de-la-app-de-envios"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Política de Privacidad
                        </a>
                        <span> | </span>
                        <a
                          href="https://bteacher.es/pages/terminos-del-servicio-de-app-envios-bteacher"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Términos y Condiciones
                        </a>
                        <span> | </span>
                        <a href="mailto:info@bteacher.es">Contacto</a>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="title-section">
                      <h2>📩 Selecciona el Envío de CV:</h2>
                      <PendingOrders API_URL={API_URL} handleOrderSelection={handleOrderSelection} pendingOrders={pendingOrders} />
                    </div>
                    <div className="title-section">
                      <SelectCenter centersList={centersList} onSelectedCentersChange={setSelectedCenters} />
                    </div>
                    <Cuestionario
                      subject={subject}
                      coverLetter={coverLetter}
                      file={file}
                      setSubject={setSubject}
                      setCoverLetter={setCoverLetter}
                      handleFileChange={(e) => setFile(e.target.files[0])}
                      sendEmail={sendEmail}
                    />
                    <button className="logout-btn" onClick={handleLogout}>
                      Eliminar Accesos
                    </button>
                    {isAdmin && ( // Renderiza el botón solo si es administrador
                      <button
                        className="logout-btn"
                        onClick={() => {
                          navigate('/admin-dashboard'); // Navegación al dashboard
                        }}
                      >
                        Acceso a Dashboard
                      </button>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        }
      />
      <Route path="/admin-dashboard" element={<AdminDashboard API_URL={API_URL} token={token} />} />
    </Routes>
  );
}

export default App;