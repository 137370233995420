// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-header {
  background-color: #0A255D; 
  padding: 20px;
  text-align: center; /* Centra el contenido del encabezado */
  border-radius: 10px 10px 10px 10px; /* Esquinas redondeadas solo en la parte superior */
}

/* Estilo para la imagen del logo */
.logo {
  max-width: 150px; /* Ajusta el tamaño máximo del logo */
  height: auto; /* Mantiene la proporción */
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB,EAAE,uCAAuC;EAC3D,kCAAkC,EAAE,mDAAmD;AACzF;;AAEA,mCAAmC;AACnC;EACE,gBAAgB,EAAE,qCAAqC;EACvD,YAAY,EAAE,2BAA2B;AAC3C","sourcesContent":[".app-header {\n  background-color: #0A255D; \n  padding: 20px;\n  text-align: center; /* Centra el contenido del encabezado */\n  border-radius: 10px 10px 10px 10px; /* Esquinas redondeadas solo en la parte superior */\n}\n\n/* Estilo para la imagen del logo */\n.logo {\n  max-width: 150px; /* Ajusta el tamaño máximo del logo */\n  height: auto; /* Mantiene la proporción */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
