import React from 'react';
import './SendButton.css';

function SendButton({ onClick }) {
  return (
    <button className="bt" onClick={onClick}>
      <span className="msg"></span>
      ENVIAR CV A CENTROS
    </button>
  );
}

export default SendButton;
