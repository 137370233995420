import React from 'react';
import './InfoCard.css';

function InfoCard({ heading, description, image }) {
  return (
    <div className="info-card">
      <div className="info-card-header">
        <img src={image} alt="Icono" className="info-card-image" />
      </div>
      <div className="info-card-body">
        <h3 className="info-card-title">{heading}</h3>
        <p className="info-card-text">{description}</p>
      </div>
    </div>
  );
}

export default InfoCard;
