import React from 'react';
import './Header.css';

function Header() {
  return (
    <header className="app-header">
      <img src="https://cdn.shopify.com/s/files/1/0611/0786/3712/files/Logo.png?v=1724878500" alt="Logo" className="logo" />
    </header>
  );
}

export default Header;