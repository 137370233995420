import React from 'react';
import './SuccessMessage.css'; // Asegúrate de que los estilos CSS estén en este archivo

const SuccessMessage = ({ onDismiss }) => {
  return (
    <div className="success-card">
      <div className="success-header">
        <div className="success-image">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <path stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#000000" d="M20 7L9.00004 18L3.99994 13"></path>
          </svg>
        </div>
        <div className="success-content">
          <span className="success-title">CV Enviado</span>
          <p className="success-message">
            Muchas gracias por tu compra. Tu CV está siendo enviado. Si tienes cualquier problema, puedes contactar con nosotros en bteacher.es@gmail.com
          </p>
        </div>
        <div className="success-actions">
          <button type="button" className="success-history" onClick={onDismiss}>Terminar envío</button>
        </div>
      </div>
    </div>
  );
};

export default SuccessMessage;