// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px; /* Aumentado el espacio superior */
  margin-bottom: 20px; /* Agregado margen inferior para separación */
  gap: 10px;
}

.pagination-arrow {
  background: none;
  border: none;
  color: #333;
  font-size: 18px;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.pagination-arrow:disabled {
  color: #e0e0e0;
  cursor: not-allowed;
}

.pagination-arrow:hover:not(:disabled) {
  color: #28a745;
}

.pagination-number {
  background: none;
  border: 1px solid #ccc;
  color: #333;
  font-size: 16px;
  padding: 6px 12px;
  cursor: pointer;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
  border-radius: 4px;
}

.pagination-number.active {
  background: #28a745;
  color: white;
  border: 1px solid #28a745;
}

.pagination-number:hover:not(.active) {
  background: #e0e0e0;
}

.pagination-ellipsis {
  color: #666;
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Pagination/Pagination.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB,EAAE,kCAAkC;EACrD,mBAAmB,EAAE,6CAA6C;EAClE,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,WAAW;EACX,eAAe;EACf,eAAe;EACf,kCAAkC;AACpC;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,+DAA+D;EAC/D,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,eAAe;AACjB","sourcesContent":[".pagination-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding-top: 20px; /* Aumentado el espacio superior */\n  margin-bottom: 20px; /* Agregado margen inferior para separación */\n  gap: 10px;\n}\n\n.pagination-arrow {\n  background: none;\n  border: none;\n  color: #333;\n  font-size: 18px;\n  cursor: pointer;\n  transition: color 0.2s ease-in-out;\n}\n\n.pagination-arrow:disabled {\n  color: #e0e0e0;\n  cursor: not-allowed;\n}\n\n.pagination-arrow:hover:not(:disabled) {\n  color: #28a745;\n}\n\n.pagination-number {\n  background: none;\n  border: 1px solid #ccc;\n  color: #333;\n  font-size: 16px;\n  padding: 6px 12px;\n  cursor: pointer;\n  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;\n  border-radius: 4px;\n}\n\n.pagination-number.active {\n  background: #28a745;\n  color: white;\n  border: 1px solid #28a745;\n}\n\n.pagination-number:hover:not(.active) {\n  background: #e0e0e0;\n}\n\n.pagination-ellipsis {\n  color: #666;\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
