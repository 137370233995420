import React from 'react';
import './Pagination.css';

function Pagination({ currentPage, totalPages, onPageChange }) {
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="pagination-container">
      <button
        className="pagination-arrow"
        onClick={handlePrevious}
        disabled={currentPage === 1}
      >
        &larr;
      </button>

      <div className="pagination-dots">
        {Array.from({ length: totalPages }, (_, index) => (
          <span
            key={index}
            className={`pagination-dot ${index + 1 === currentPage ? 'active' : ''}`}
          ></span>
        ))}
      </div>

      <button
        className="pagination-arrow"
        onClick={handleNext}
        disabled={currentPage === totalPages}
      >
        &rarr;
      </button>
    </div>
  );
}

export default Pagination;
