import React from 'react';
import './UserForm.css';
import SendButton from '../SendButton/SendButton'; 

function UserForm({ subject, coverLetter, file, setSubject, setCoverLetter, handleFileChange, sendEmail }) {
  return (
    <div className="form-section">
      <h2>Rellena información personal:</h2>

      <label htmlFor="subject">Asunto</label>
      <input
        type="text"
        className="input-field"
        id="subject"
        placeholder="Escribe el asunto del correo"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />
      <p className="field-description">Este será el título de tu correo. Sé claro y directo.</p>

      <label htmlFor="coverLetter">Carta de presentación</label>
      <textarea
        className="full-width-textarea"
        id="coverLetter"
        placeholder="Escribe tu carta de presentación"
        value={coverLetter}
        onChange={(e) => setCoverLetter(e.target.value)}
      />
      <p className="field-description">Aquí puedes escribir una breve descripción de por qué eres el candidato ideal.</p>

      <label htmlFor="file">Adjuntar CV (PDF, DOC)</label>
      <input
        type="file"
        className="file-input"
        id="file"
        accept=".pdf,.doc,.docx"
        onChange={handleFileChange}
      />

      <SendButton onClick={sendEmail} />
    </div>
  );
}

export default UserForm;
