// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 320px;
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    background: #EF665B;
    border-radius: 8px;
    box-shadow: 0px 0px 5px -3px #111;
    position: fixed; /* Ahora el mensaje es flotante */
    bottom: 20px; /* Alinea en la parte inferior */
    left: 50%; /* Centra horizontalmente */
    transform: translateX(-50%); /* Ajuste para centrar bien el mensaje */
    z-index: 1000; /* Asegura que esté por encima de otros elementos */
  }
  
  .error__icon {
    width: 20px;
    height: 20px;
    transform: translateY(-2px);
    margin-right: 8px;
  }
  
  .error__icon path {
    fill: #fff;
  }
  
  .error__title {
    font-weight: 500;
    font-size: 14px;
    color: #fff;
  }
  
  .error__close {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: auto;
  }
  
  .error__close path {
    fill: #fff;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ErrorMessage/ErrorMessage.css"],"names":[],"mappings":"AAAA;IACI,mJAAmJ;IACnJ,YAAY;IACZ,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,iCAAiC;IACjC,eAAe,EAAE,iCAAiC;IAClD,YAAY,EAAE,gCAAgC;IAC9C,SAAS,EAAE,2BAA2B;IACtC,2BAA2B,EAAE,wCAAwC;IACrE,aAAa,EAAE,mDAAmD;EACpE;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,2BAA2B;IAC3B,iBAAiB;EACnB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,gBAAgB;IAChB,eAAe;IACf,WAAW;EACb;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,UAAU;EACZ","sourcesContent":[".error {\n    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;\n    width: 320px;\n    padding: 12px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: start;\n    background: #EF665B;\n    border-radius: 8px;\n    box-shadow: 0px 0px 5px -3px #111;\n    position: fixed; /* Ahora el mensaje es flotante */\n    bottom: 20px; /* Alinea en la parte inferior */\n    left: 50%; /* Centra horizontalmente */\n    transform: translateX(-50%); /* Ajuste para centrar bien el mensaje */\n    z-index: 1000; /* Asegura que esté por encima de otros elementos */\n  }\n  \n  .error__icon {\n    width: 20px;\n    height: 20px;\n    transform: translateY(-2px);\n    margin-right: 8px;\n  }\n  \n  .error__icon path {\n    fill: #fff;\n  }\n  \n  .error__title {\n    font-weight: 500;\n    font-size: 14px;\n    color: #fff;\n  }\n  \n  .error__close {\n    width: 20px;\n    height: 20px;\n    cursor: pointer;\n    margin-left: auto;\n  }\n  \n  .error__close path {\n    fill: #fff;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
