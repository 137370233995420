import React from 'react';
import './PendingOrders.css';

function PendingOrders({ pendingOrders, selectedOrder, handleOrderSelection }) {
  if (pendingOrders.length === 0) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <img 
          src="https://cdn.shopify.com/s/files/1/0611/0786/3712/files/vacio.png?v=1731023438" 
          alt="Sin pedidos pendientes" 
          style={{ width: '100px', marginBottom: '10px' }} 
        />
        <p>
          No hay pedidos pendientes de realizar. Visita{' '}
          <a href="https://bteacher.es" target="_blank" rel="noopener noreferrer">
            bteacher.es
          </a>{' '}
          para más información.
        </p>
      </div>
    );
    
    
  }

  return (
    <div className="pending-orders-list">
      {pendingOrders.map(order => (
        <div
          key={order.order_id}
          className={`order-btn ${selectedOrder === order.order_id ? 'selected' : ''}`}
          onClick={() => handleOrderSelection(order.order_id)}
        >
          <label className="container">
            <input type="checkbox" checked={selectedOrder === order.order_id} />
            <div className="checkmark"></div>
          </label>

          <img src={order.product_flag} alt={order.product_name} className="product-flag" />
          <div className="order-info">{order.product_name}</div>
        </div>
      ))}
    </div>
  );
}

export default PendingOrders;
