import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './AdminDashboard.css';


const AdminDashboard = ({ API_URL, token }) => {
  const navigate = useNavigate();

  /*const [userData, setUserData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [centerData, setCenterData] = useState([]);*/

  const [userData, setUserData] = useState([]); // ✅ Asegurar que es un array
  const [productData, setProductData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [centerData, setCenterData] = useState([]);


  const userColumns = [
    { headerName: 'ID', field: 'id', sortable: true, filter: true },
    { headerName: 'Email', field: 'email', editable: true, sortable: true, filter: true},
    { headerName: 'Rol', field: 'rol', editable: true },
    { headerName: 'Session ID', field: 'session_id', editable: true },
    { headerName: 'Token', field: 'token', editable: true },
    { headerName: 'State App', field: 'state_app', editable: true },
    { headerName: 'Token Remain', field: 'token_remain', editable: true },
    { headerName: 'Created At', field: 'created_at', editable: false },
    { headerName: 'Last Sent', field: 'last_sent', editable: true },
  ];

  const productColumns = [
    { headerName: 'ID', field: 'id', sortable: true, filter: true },
    { headerName: 'Nombre', field: 'name', editable: true, sortable: true, filter: true},
    { headerName: 'Etapa Educativa', field: 'etapa_educativa', editable: true, sortable: true, filter: true },
    { headerName: 'Comunidad', field: 'comunidad_producto', editable: true, sortable: true, filter: true },
    { headerName: 'Flag URL', field: 'flag_url', editable: true },
  ];

  const orderColumns = [
    { headerName: 'ID', field: 'id', sortable: true, filter: true }, // CAMBIO AQUÍ
    { headerName: 'Email', field: 'email', editable: true, sortable: true, filter: true },
    { headerName: 'Product ID', field: 'product_id', editable: true },
    { headerName: 'Product Name', field: 'product_name', editable: false },
    { headerName: 'Status', field: 'status', editable: true, sortable: true, filter: true },
    { headerName: 'Created At', field: 'created_at', editable: false },
    { headerName: 'Used At', field: 'used_at', editable: false },
    { headerName: 'Centers Sent', field: 'centers_sent', editable: false, cellClass: 'ag-selectable', },
    { headerName: 'Asunto', field: 'asunto_email', editable: false, cellClass: 'ag-selectable' },  // ✅ NUEVO
    { headerName: 'Cuerpo del Correo', field: 'carta_email', editable: false, cellClass: 'ag-selectable' },  // ✅ NUEVO
    { headerName: 'Pedido Shopify', field: 'pedido_shopify', editable: false, cellClass: 'ag-selectable', sortable: true, filter: true },  // ✅ NUEVO
  ];
  

  const centerColumns = [
    { headerName: 'ID', field: 'id', sortable: true, filter: true },
    { headerName: 'Nombre del Centro', field: 'center_name', sortable: true, editable: true, filter: true },
    { headerName: 'Comunidad', field: 'community', editable: true, sortable: true, filter: true },
    { headerName: 'Provincia', field: 'provincia', editable: true },
    { headerName: 'Etapa Educativa', field: 'etapa_educativa', editable: true, sortable: true, filter: true },
    { headerName: 'Email', field: 'email', editable: true, sortable: true, filter: true },
  ];

  const [userGridApi, setUserGridApi] = useState(null);
  const [productGridApi, setProductGridApi] = useState(null);
  const [orderGridApi, setOrderGridApi] = useState(null);
  const [centerGridApi, setCenterGridApi] = useState(null);




  useEffect(() => {
    if (!token) return;
  
    const fetchData = async (url, setState, dataKey = null) => {
      console.log(`📡 Solicitando datos de: ${url}`);
  
      try {
        const response = await fetch(`${url}?nocache=${Date.now()}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        console.log(`📥 Respuesta de ${url}:`, response.status, response.statusText);
  
        if (!response.ok) {
          throw new Error(`Error en la solicitud: ${response.status} - ${response.statusText}`);
        }
  
        const data = await response.json();
        console.log(`✅ Datos recibidos de ${url}:`, data);
  
        const finalData = dataKey ? data[dataKey] : data;
  
        if (!Array.isArray(finalData)) {
          console.warn(`⚠️ La respuesta de ${url} no es un array`, finalData);
          setState([]);
        } else {
          setState(finalData);
        }
  
      } catch (error) {
        console.error(`❌ Error al obtener datos de ${url}:`, error.message);
        setState([]);
      }
    };
  
    fetchData(`${API_URL}/api/users`, setUserData);
    fetchData(`${API_URL}/api/products`, setProductData);
    
    // ✅ Ahora verificamos que `data` sea un array antes de usarlo
    fetchData(`${API_URL}/api/orders`, (data) => {
      if (!Array.isArray(data)) {
        console.warn(`⚠️ Datos de órdenes inválidos:`, data);
        setOrderData([]);
        return;
      }
  
      const transformedOrders = data.map(order => ({
        id: order.order_id,  
        email: order.email,
        product_id: order.product_id,
        product_name: order.product_name,
        status: order.status,
        created_at: order.created_at,
        used_at: order.used_at,
        centers_sent: order.centers_sent || '',
        asunto_email: order.asunto_email || '',     
        carta_email: order.carta_email || '',
        pedido_shopify: order.pedido_shopify ||'',
      }));
      console.log("🧾 Pedidos transformados:", transformedOrders); // <- AÑADIR ESTO
      setOrderData(transformedOrders);
    }, "data");
  
    fetchData(`${API_URL}/api/centers`, setCenterData);
  
  }, [API_URL, token]);
  

  
  // CRUD Handlers para Usuarios
  const handleUserAdd = () => {
    const newUser = {
      email: `new_user_${Date.now()}@example.com`,
      rol: 'user',
      session_id: 'new_session_id',
      token: 'new_token',
      state_app: 'new_state',
      token_remain: 1,
    };

    fetch(`${API_URL}/api/add-user`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(newUser),
    })
      .then((response) => response.json())
      .then((data) => setUserData((prev) => [...prev, { ...newUser, id: data.id }]))
      .catch(() => console.error('Error al añadir usuario.'));
  };

  const handleUserDelete = () => {
    const selectedNode = userGridApi.getSelectedNodes()[0];
    if (!selectedNode) {
      alert('Selecciona un usuario para eliminar.');
      return;
    }

    const userId = selectedNode.data.id;

    fetch(`${API_URL}/api/delete-user`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify({ id: userId }),
    })
      .then(() => setUserData((prev) => prev.filter((user) => user.id !== userId)))
      .catch(() => console.error('Error al eliminar usuario.'));
  };

  const handleUserEdit = (params) => {
    const updatedUser = params.data;

    fetch(`${API_URL}/api/edit-user`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(updatedUser),
    })
      .then(() => {
        setUserData((prev) => prev.map((user) => (user.id === updatedUser.id ? updatedUser : user)));
      })
      .catch(() => console.error('Error al editar usuario.'));
  };

  // CRUD Handlers para Productos
  const handleProductAdd = () => {
    const newProduct = {
      name: `Nuevo Producto ${Date.now()}`,
      alcance: '',
      flag_url: '',
      comunidad_producto: '',
    };

    fetch(`${API_URL}/api/add-product`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(newProduct),
    })
      .then((response) => response.json())
      .then((data) => setProductData((prev) => [...prev, { ...newProduct, id: data.id }]))
      .catch(() => console.error('Error al añadir producto.'));
  };

  const handleProductDelete = () => {
    const selectedNode = productGridApi.getSelectedNodes()[0];
    if (!selectedNode) {
      alert('Selecciona un producto para eliminar.');
      return;
    }

    const productId = selectedNode.data.id;

    fetch(`${API_URL}/api/delete-product`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify({ id: productId }),
    })
      .then(() => setProductData((prev) => prev.filter((product) => product.id !== productId)))
      .catch(() => console.error('Error al eliminar producto.'));
  };

  const handleProductEdit = (params) => {
    const updatedProduct = params.data;

    fetch(`${API_URL}/api/edit-product`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(updatedProduct),
    })
      .then(() => {
        setProductData((prev) => prev.map((product) => (product.id === updatedProduct.id ? updatedProduct : product)));
      })
      .catch(() => console.error('Error al editar producto.'));
  };

  // CRUD Handlers para Pedidos
  const handleOrderAdd = () => {
    const newOrder = {
      email: `order_user_${Date.now()}@example.com`,
      product_id: 1, // Reemplazar con un ID válido
      status: 'Pending',
    };
  
    fetch(`${API_URL}/api/add-order`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(newOrder),
    })
      .then((response) => response.json())
      .then((data) => {
        const createdOrder = {
          id: data.order_id || data.id, // fallback
          ...newOrder,
          created_at: data.created_at || new Date().toISOString(),
          used_at: data.used_at || null,
          product_name: data.product_name || '',
          centers_sent: data.centers_sent || '',
        };
        setOrderData((prev) => [...prev, createdOrder]);
      })
      .catch(() => console.error('Error al añadir pedido.'));
  };


  const handleOrderDelete = () => {
    const selectedNode = orderGridApi.getSelectedNodes()[0];
    if (!selectedNode) {
      alert('Selecciona un pedido para eliminar.');
      return;
    }

    const orderId = selectedNode.data.id; // CAMBIO AQUÍ


    fetch(`${API_URL}/api/delete-order`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify({ id: orderId }),
    })
      .then(() => setOrderData((prev) => prev.filter((order) => order.id !== orderId)))
      .catch(() => console.error('Error al eliminar pedido.'));
  };

  const handleOrderEdit = (params) => {
    const updatedOrder = { ...params.data, id: params.data.id }; // CAMBIO AQUÍ


    console.log('Datos enviados al backend para edición de pedido:', updatedOrder);

    fetch(`${API_URL}/api/edit-order`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(updatedOrder),
    })
      .then(() => {
        setOrderData((prev) => prev.map((order) => (order.id === updatedOrder.id ? updatedOrder : order)));
      })
      .catch(() => console.error('Error al editar pedido.'));
  };


  //CRUD PARA CENTROS EDUCATIVOS
  const handleCenterAdd = () => {
    const newCenter = {
      center_name: `Nuevo Centro ${Date.now()}`,
      community: 'Comunidad X',
      email: `centro${Date.now()}@example.com`,
    };

    fetch(`${API_URL}/api/add-center`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(newCenter),
    })
      .then((response) => response.json())
      .then((data) => setCenterData((prev) => [...prev, { ...newCenter, id: data.id }]))
      .catch(() => console.error('Error al añadir centro educativo.'));
  };

  const handleCenterEdit = (updatedCenter) => {
    fetch(`${API_URL}/api/edit-center`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(updatedCenter),
    })
      .then((response) => response.json())
      .then(() => {
        setCenterData((prev) =>
          prev.map((center) => (center.id === updatedCenter.id ? updatedCenter : center))
        );
      })
      .catch(() => console.error('Error al editar centro educativo.'));
  };

  const handleCenterDelete = () => {
    const selectedNode = centerGridApi.getSelectedNodes()[0];
    if (!selectedNode) {
      alert('Selecciona un centro para eliminar.');
      return;
    }

    const centerId = selectedNode.data.id;

    fetch(`${API_URL}/api/delete-center`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify({ id: centerId }),
    })
      .then(() => setCenterData((prev) => prev.filter((center) => center.id !== centerId)))
      .catch(() => console.error('Error al eliminar centro educativo.'));
  };





  return (
    <div className="admin-dashboard ag-theme-alpine">
      <h1>Panel de Administración</h1>

      <section style={{ marginBottom: '20px' }}>
        <h2>Usuarios</h2>
        <div style={{ height: '400px', width: '100%' }}>
          <AgGridReact
            rowData={userData}
            columnDefs={userColumns}
            rowSelection={{ type: 'single' }}
            onGridReady={(params) => setUserGridApi(params.api)}
            onCellValueChanged={handleUserEdit}
          />
        </div>
        <div style={{ display: 'flex', marginTop: '10px', gap: '10px' }}>
          <button
            onClick={handleUserAdd}
            style={{ backgroundColor: 'green', color: 'white', padding: '10px' }}
          >
            Añadir Usuario
          </button>
          <button
            onClick={handleUserDelete}
            style={{ backgroundColor: 'red', color: 'white', padding: '10px' }}
          >
            Eliminar Usuario
          </button>
        </div>
      </section>

      <section style={{ marginBottom: '20px' }}>
        <h2>Productos</h2>
        <div style={{ height: '400px', width: '100%' }}>
          <AgGridReact
            rowData={productData}
            columnDefs={productColumns}
            rowSelection={{ type: 'single' }}
            onGridReady={(params) => setProductGridApi(params.api)}
            onCellValueChanged={handleProductEdit}
          />
        </div>
        <div style={{ display: 'flex', marginTop: '10px', gap: '10px' }}>
          <button
            onClick={handleProductAdd}
            style={{ backgroundColor: 'green', color: 'white', padding: '10px' }}
          >
            Añadir Producto
          </button>
          <button
            onClick={handleProductDelete}
            style={{ backgroundColor: 'red', color: 'white', padding: '10px' }}
          >
            Eliminar Producto
          </button>
        </div>
      </section>

      <section style={{ marginBottom: '20px' }}>
        <h2>Pedidos</h2>
        <div style={{ height: '400px', width: '100%' }}>
          <AgGridReact
            rowData={orderData}
            columnDefs={orderColumns}
            rowSelection={{ type: 'single' }}
            onGridReady={(params) => setOrderGridApi(params.api)}
            onCellValueChanged={handleOrderEdit}
          />
        </div>
        <div style={{ display: 'flex', marginTop: '10px', gap: '10px' }}>
          <button
            onClick={handleOrderAdd}
            style={{ backgroundColor: 'green', color: 'white', padding: '10px' }}
          >
            Añadir Pedido
          </button>
          <button
            onClick={handleOrderDelete}
            style={{ backgroundColor: 'red', color: 'white', padding: '10px' }}
          >
            Eliminar Pedido
          </button>
        </div>
      </section>

      <section style={{ marginBottom: '20px' }}>
        <h2>Centros Educativos</h2>
        <div style={{ height: '400px', width: '100%' }}>
          <AgGridReact
            rowData={centerData}
            columnDefs={centerColumns}
            rowSelection={{ type: 'single' }}
            onGridReady={(params) => setCenterGridApi(params.api)}
            onCellValueChanged={(params) => handleCenterEdit(params.data)}
          />
        </div>
        <div style={{ display: 'flex', marginTop: '10px', gap: '10px' }}>
          <button
            onClick={handleCenterAdd}
            style={{ backgroundColor: 'green', color: 'white', padding: '10px' }}
          >
            Añadir Centro
          </button>
          <button
            onClick={handleCenterDelete}
            style={{ backgroundColor: 'red', color: 'white', padding: '10px' }}
          >
            Eliminar Centro
          </button>
        </div>
      </section>



      <button
        onClick={() => navigate('/')}
        style={{ backgroundColor: '#007bff', color: 'white', padding: '10px' }}
      >
        Volver a la Página Principal
      </button>
    </div>
  );
};

export default AdminDashboard;